import React, {Component} from 'react';
import { Link} from 'react-router-dom';

import {SlideUpDown} from "../../../services/script"
import styles from './footer-one.module.scss';
import LogoImage from "../headers/common/logo"

class FooterOne extends Component {

    componentDidMount(){
        var contentwidth = window.innerWidth;
        if ((contentwidth) < 750) {
            SlideUpDown('footer-title');
        } else {
            var elems = document.querySelectorAll(".footer-title");
            [].forEach.call(elems, function(elemt) {
                let el = elemt.nextElementSibling;
                el.style = "display: block";
            });
        }
    }


    render () {

        return (
            <footer className={`footer-light ${styles.footerSection}`}>
                <section style={{paddingTop: 80, paddingBottom: 80}}>
                    <div className="container">
                        <div className="row footer-theme partition-f">
                            <div className="col-lg-4 col-md-6">
                                <div className="footer-title footer-mobile-title">
                                    <h4>About</h4>
                                </div>
                                <div className={`${styles.footerContent} ${styles.aboutContentWrapper}`}>
                                    <div className="footer-logo" style={{textAlign: 'center'}}>
                                        {/*<LogoImage logo={this.props.logoName} />*/}
                                        <Link to={`${process.env.PUBLIC_URL}`}>
                                        <img
                                            style={{
                                                maxWidth: 179,
                                                maxHeight: 80
                                            }}
                                            src={`${process.env.PUBLIC_URL}/assets/images/wm/logo-nail-new.png`}
                                            // srcSet={`${process.env.PUBLIC_URL}/assets/images/wm/logo-hammer@2x.png 2x`}
                                            className="img-fluid" alt="Nail"/>
                                        </Link>
                                    </div>
                                    <p style={{textAlign: 'center'}}>The second brand of PT Warna Mardhika present in 1995, namely NAIL. NAIL focuses on the uniqueness of modern men's shirt is devoted for them who casual style, but still looks exclusive.</p>
                                    {/*<div className="footer-social">
                                        <ul>
                                            <li>
                                                <Link to={'https://www.facebook.com/'} ><i className="fa fa-facebook" aria-hidden="true"></i></Link>
                                            </li>
                                            <li>
                                                <Link to={'https://plus.google.com/'} ><i className="fa fa-google-plus" aria-hidden="true"></i></Link>
                                            </li>
                                            <li>
                                                <Link to={'https://twitter.com'}><i className="fa fa-twitter" aria-hidden="true"></i></Link>
                                            </li>
                                            <li>
                                                <Link to={'https://instagram.com'}><i className="fa fa-instagram" aria-hidden="true"></i></Link>
                                            </li>
                                            <li>
                                                <Link to={'https://rss.com/'}><i className="fa fa-rss" aria-hidden="true"></i></Link>
                                            </li>
                                        </ul>
                                    </div>*/}
                                </div>
                            </div>
                            <div className="col offset-xl-1">
                                <div className="sub-title">
                                    <div className={styles.footerTitle}>
                                        <h4>Company</h4>
                                    </div>
                                    <div className={styles.footerContent}>
                                        <ul>
                                            <li><Link to={`/stores`} >Stores</Link></li>
                                            <li><Link to={`/faq`} >FAQ</Link></li>
                                            <li><Link to={`/tnc`} >Terms & Conditions</Link></li>
                                            <li><a href="https://hammer-clothing.com/" target="_blank" rel="noopener noreferrer" >Hammer</a></li>
                                            <li><a href="https://coconutisland75.com/" target="_blank" rel="noopener noreferrer" >Coconut Island</a></li>
                                            <li><a href="http://warna-mardhika.com/v2/" target="_blank" rel="noopener noreferrer" >Warna Mardhika</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="sub-title">
                                    <div className={styles.footerTitle}>
                                        <h4>Contact</h4>
                                    </div>
                                    <div className={styles.footerContent}>
                                        <ul className="contact-list">
                                            <li style={{textTransform: 'none'}}><i className="fa fa-map-marker"/>PT Warna Mardhika</li>
                                            <li style={{textTransform: 'none'}}><i className="fa fa-phone"/><a href="tel:+62811879191">+62-811-879-191</a></li>
                                            <li style={{textTransform: 'none'}}><i className="fa fa-whatsapp"/><a href="https://api.whatsapp.com/send?phone=62811879191" target="_blank" rel="noopener noreferrer">Chat with us</a></li>
                                            <li style={{textTransform: 'none'}}><i className="fa fa-envelope-o"/><a href="mailto:ecommerce@warna-mardhika.com">ecommerce@warna-mardhika.com</a></li>
                                            {/*<li><i className="fa fa-fax"></i>Fax: 123456</li>*/}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="sub-title">
                                    <div className={styles.footerTitle}>
                                        <h4>Social</h4>
                                    </div>
                                    <div className={styles.footerContent}>
                                        <ul className="contact-list">
                                            {/*<li style={{textTransform: 'none'}}>
                                                <i className="fa fa-facebook"/>
                                                <a target="_blank"
                                                   rel="noopener noreferrer"
                                                   href="https://www.facebook.com/Hammer-1987-110968107478365">@Hammer-1987</a>
                                            </li>*/}
                                            <li style={{textTransform: 'none'}}>
                                                <i className="fa fa-instagram"/>
                                                <a target="_blank"
                                                   rel="noopener noreferrer"
                                                   href="https://www.instagram.com/nailbrandofficial/">@nailbrandofficial</a>
                                            </li>
                                            {/*<li style={{textTransform: 'none'}}>
                                                <i className="fa fa-youtube"/>
                                                <a target="_blank"
                                                   rel="noopener noreferrer"
                                                   href="https://www.youtube.com/channel/UCKHzseB2z70R5pDtIsQr2Zw">Coconut Island</a>
                                            </li>*/}
                                            {/*<li><i className="fa fa-map-marker"></i>PT Warna Mardhika</li>
                                            <li><i className="fa fa-map-marker"></i>PT Warna Mardhika</li>
                                            <li><i className="fa fa-phone"></i>Call Us: 123-456-7898</li>
                                            <li><i className="fa fa-envelope-o"></i>Email Us: <a href="#">Support@Fiot.com</a></li>
                                            <li><i className="fa fa-fax"></i>Fax: 123456</li>*/}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*<div className="sub-footer ">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-md-6 col-sm-12">
                                <div className="footer-end">
                                    <p><i className="fa fa-copyright" aria-hidden="true"></i> 2020 PT Warna Mardhika</p>
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6 col-sm-12">
                                <div className="payment-card-bottom">
                                    <ul>
                                        <li>
                                            <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/visa.png`} alt="" /></a>
                                        </li>
                                        <li>
                                            <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/mastercard.png`} alt="" /></a>
                                        </li>
                                        <li>
                                            <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/paypal.png`} alt="" /></a>
                                        </li>
                                        <li>
                                            <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/american-express.png`} alt="" /></a>
                                        </li>
                                        <li>
                                            <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/discover.png`} alt="" /></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>*/}

                <a href="https://api.whatsapp.com/send?phone=62811879191"
                   className={styles.float} target="_blank" rel="noopener noreferrer">
                    <i className={`fa fa-whatsapp ${styles.myFloat}`} /> Chat Us
                </a>
            </footer>
        )
    }
}

export default FooterOne;